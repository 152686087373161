import CmsBlock from 'Component/CmsBlock';
import CmsBlockProvider from 'Component/CmsBlockProvider';
import ContentWrapper from 'Component/ContentWrapper';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';

import './Footer.style';

/** @namespace Pwa/Component/Footer/Component/Footer */
export class Footer extends SourceFooter {
    shouldComponentUpdate(nextProps) {
        const {
            device: { isMobile },
            isVisibleOnMobile,
            copyright,
            isCheckout,
            newsletterActive,
        } = this.props;

        const {
            device: { isMobile: nextIsMobile },
            isVisibleOnMobile: nextIsVisibleOnMobile,
            isCheckout: nextIsCheckout,
            copyright: nextCopyright,
            newsletterActive: nextNewsletterActive,
        } = nextProps;

        return (
            isMobile !== nextIsMobile ||
            isVisibleOnMobile !== nextIsVisibleOnMobile ||
            copyright !== nextCopyright ||
            newsletterActive !== nextNewsletterActive ||
            isCheckout !== nextIsCheckout
        );
    }

    renderNewsletter() {
        return (
            <div className="Footer-Newsletter">
                <NewsletterSubscription key="NewsletterSubscription" />
            </div>
        );
    }

    renderBasicContent() {
        return (
            <ContentWrapper label={__('Footer')}>
                <CmsBlock identifier="footer_info" blockType="footerInfo" />
                <CmsBlock identifier="footer_links" blockType="footerLinks" />
                {this.renderNewsletter()}
                <CmsBlock identifier="footer_socials" blockType="footerSocials" />
            </ContentWrapper>
        );
    }

    renderCheckoutContent() {
        return (
            <ContentWrapper label={__('Footer')}>
                <CmsBlock identifier="footer_checkout" blockType="footerCheckout" />
            </ContentWrapper>
        );
    }

    renderContent() {
        const { isCheckout } = this.props;

        return (
            <CmsBlockProvider identifiers={['footer_info', 'footer_links', 'footer_socials', 'footer_checkout']}>
                {isCheckout ? this.renderCheckoutContent() : this.renderBasicContent()}{' '}
            </CmsBlockProvider>
        );
    }

    render() {
        return <footer block="Footer">{this.renderContent()}</footer>;
    }
}

export default Footer;
